.EditProfile{
    overflow-x: hidden;
}

.alternateNum-btn{
    border:1px solid black !important;
    border-radius: 20px !important;
    margin-top: 5px;
    float: right;
}
.ant-form-item-label {
    text-align: right;
}

.submit{
    background-color: #265090;
    color: white;
}

.submit:focus{
    background-color: #265090;
    color: white;
}

.profile_image_edit{
    margin: 20px 0px;
    font-size: 12px;
    display: grid;
    place-items: center;
    background-color: var(--blue);
    color: white;
    display: flex;
    padding: 7px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    width: fit-content;
}
.profile_image_edit svg{
    font-size: 13px;
    margin-right: 7px;
}
.EditLayout{
    margin-right: 25px
}
