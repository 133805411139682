.editRate {
    text-align: -webkit-center;
}

@media only screen and (min-width:551px) {
    .EditCollapse {
        width: 70%;
    }

    .collapseMainContainer{
        display: flex;
        justify-content: center;
    }

    .editable-cell-value-wrap {
        padding: 2px !important;
    }
    .site_images{
        height: 500px !important;
        width: 465px !important;
    }
}

@media only screen and (max-width:550px) {
    .editRate {
        height: 87vh;
    }
    .site_images{
        height: 250px !important;
        width: 300px !important;
    }
    .siteImageModal > .ant-modal-content > .ant-modal-body{
        height: 350px !important;
    }
}

.editRate .editable-cell {
    position: relative;
}

.editRate .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
    background-color: rgb(189, 230, 255);

}

.S-editable-cell-value-wrap {
    background-color: var(--blue);
}

.B-editable-cell-value-wrap {
    background-color: var(--orange);
}

.editRate .ant-row .ant-form-item {
    width: 30%;
}

.editRate .editable-row:hover .editable-cell-value-wrap:hover {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    background-color: rgb(189, 230, 255);
}

.editRate .ant-table-thead>tr>th {
    background: #4e8ae4;
    font-size: 1.1rem;
    color: whitesmoke;
    text-align: center;
}

.editRate .ant-table-thead>tr>th.col-buyer {
    background-color: var(--orange) !important;
    font-size: 13px;

}

.editRate .ant-table-thead>tr>th.col-seller {
    background-color: var(--blue);
    font-size: 13px;

}

/* .ant-table-tbody > tr.ant-table-row .highlight:hover{
    background-color:rgb(189, 230, 255);
} */


/* 
.ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
    width: 0px !important; 
} */

.orderNow_Btn{
    color: var(--blue) !important;
    border-color: var(--blue) !important; 
    
}

.info-icon-buyer .ant-table-thead>tr>th.col-buyer{
    background-color: var(--orange) !important;
}