.policy-content-container {
    margin: 7% 10% 0 10%;
    text-align: justify;
    font-size: 16px;
    word-break: break-word;
    font-family: 'Nirmala UI Semilight';
}

@media screen and (max-width:760px) { 
    .policy-content-container {
        margin: 5% 5% 0 5%;
        font-size: 14px;
    }
}