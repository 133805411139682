@import '~antd/dist/antd.css';

container-login{
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: .9;
}

  .user-register-panel {
    position: absolute;
    top: 49%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 450px;
    height: 550px;
    background: rgb(255, 255, 255);
    border-radius: 15px;
    box-shadow: rgba(200, 200, 200, 0.63) 2px 2px 7px 2px;
    padding: 30px 5%;
  }

  .user-register-panel .register-form {
    max-width: 300px;
    position: absolute;
    top: 47% !important;
    left: 47%;
    transform: translate(-50%, -50%);
  }
  .register-form-forgot {
    float: left;
    color: #8f8f8f;
    display: flex;
  }
  .register-form-forgot > div:nth-child(2) {
    float: right;
  }
  .ant-col-rtl .register-form-forgot {
    float: left;
  }
  .register-form-button {
    width: 100%;
  }

  /* .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #ff8d1a;
    border-color: #ff8d1a;
  } */

  .ant-form-item-explain.ant-form-item-explain-error {
    color: var(--orange);
  }

  .ant-form-item-has-error .ant-input-affix-wrapper {
    border-color: var(--orange);
  }

  .ant-form-item-control-input-content {
    text-align: left;
  }

  .ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
      width: 35vw;
  }

  @media (min-width:700px) {
    
    .help-container{
      position: absolute;
      bottom: 0;
      right: 0;
      margin: 1px 20px 10px 0px;
      cursor: pointer;
    }

  .top-register{
    background-image: url('../../images/labour_bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 3%;
    background-position-y: 1%;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--blue);
  }

  .user-register-panel {
    position: absolute;
    top: 49%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 450px;
    height: 550px;
    margin-top: 10px;
    background: rgb(255, 255, 255);
     border-radius: 15px;
    box-shadow: rgba(200, 200, 200, 0.63) 2px 2px 7px 2px;
    padding: 30px 5%;
  }

}

  @media (max-width: 700px) {

    .ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
        width: 85vw;
    }

    .help-container{
      position: absolute;
      bottom: 0;
      right: 0;
      margin: 1px 20px 10px 0px;
      cursor: pointer;
    }

    .register-form {
      max-width: 300px;
      position: absolute;
      top: 48% !important;
      left: 47% !important;
      margin: 10%;
      transform: translate(-50%, -50%);
    }
  
    .top-register{
      background-image: url('../../images/labour_bg.jpg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      width: 100%;
      height: 50%;
      border-bottom-left-radius: 20%;
      border-bottom-right-radius: 20%;
      background-color: var(--blue);
    }
  
    .user-register-panel{
      position: absolute;
      transform: translate(-50%, -50%);
      width: 330px;
      height: 550px;
      margin-top: 15%;
      margin-left: 0%;
      background: rgb(255, 255, 255);
       border-radius: 15px;
      box-shadow: rgba(200, 200, 200, 0.63) 2px 2px 7px 2px;
      padding: 3% 5%;
    }


  }