/* .helpPageicon{
    display: block;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin-top: 20px;
    color: white;
    text-align: center;
    line-height: 50px;
    background: var(--blue);
    box-shadow: 0 20px 20px -10px rgba(66, 165, 245, 0.5);
    margin-inline: 7px;
    font-size: 1.5em;
} */
/* .Help{
    overflow: scroll;
    height: 73vh;
} */
.helpPageicon:hover{
    background-color: var(--orange);
}
.helpPageicon>i{
    margin-left: 10px;
}
 iframe{
    height: 222px;
}
@media only screen and (min-width: 700px){
    iframe{
        height: 631px;
        /* width: 900px; */
    }
}

/* media quesries for tablet */

@media only screen 
and (min-device-width: 800px) 
and (max-device-height: 1280px) {
    iframe{
        height: 70vh;
        /* width: 900px; */
    }  
}

.image-tab{
    width: 50px;
    height: 50px;
}

.fig-caption{
    text-align: center;
}

.help_category_image{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background:rgb(141 193 235);
    padding: 5px;
  }
  
  .help_category_fig{
    margin: 0 10px 0em ;
  }


/* media queries for help icon tab in mobile view */

@media (max-width: 700px) {
    .fixed-help-icon-container > .ant-tabs > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab  {
        margin: 0 5px 0 5px !important;
      }
    .help_category_fig{
        margin: 0 0px 0em !important ;
    }
}

/* media queries for Modal in mobile view */

@media (max-width: 767px){
    .ant-modal-root > .ant-modal-wrap > .menu-modal{
        max-width: 767px !important;
        width: 100% !important;
    }
}



