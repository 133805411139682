.trade-map-container {
    position: relative;
}

.trade-map-button-container {
    position: absolute;
    top: 9%;
    right: 7px;
}

.trade-back-button {
    font-size: 16px !important;
    font-weight: bold;
}