@import '~antd/dist/antd.css';

  .mpin-panel {
    position: absolute;
    top: 49%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 450px;
    height: 550px;
    background: rgb(255, 255, 255);
    border-radius: 15px;
    box-shadow: rgba(200, 200, 200, 0.63) 2px 2px 7px 2px;
    padding: 30px 5%;
  }

  .mpin-panel .mpin-form {
    max-width: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .mpin-form-forgot {
    float: left;
    color: #8f8f8f;
    display: flex;
  }
  .mpin-form-forgot > div:nth-child(2) {
    float: right;
  }
  .ant-col-rtl .mpin-form-forgot {
    float: left;
  }
  .mpin-form-button {
    width: 100%;
  }

  .ant-btn-primary {
    background: var(--orange);
    border-color: var(--orange);
  }

  /* .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #ff8d1a;
    border-color: #ff8d1a;
  } */

  .ant-form-item-explain.ant-form-item-explain-error {
    color: var(--orange);
  }

  .ant-form-item-has-error .ant-input-affix-wrapper {
    border-color: var(--orange);
  }

  .ant-form-item-control-input-content {
    text-align: left;
  }

  .ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
      width: 35vw;
  }

  @media (max-width: 600px) {
    .ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
        width: 85vw;
    }

    .mpin-panel {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        background: rgb(255, 255, 255);
        border-radius: 0px;
        box-shadow: rgba(200, 200, 200, 0.63) 2px 2px 7px 2px;
    }
  }