@import '~antd/dist/antd.css';

.default_container_padding {
  padding-bottom: 30px;
  padding-left: 60px;
  padding-top: 50px;
}

.ant-table-thead>tr>th {
  /* background: #4e8ae4; */
  background: hsl(227deg 59% 41%);
  /* background: hsl(227, 91%, 73%); */
  font-size: 0.9rem !important;
  color: whitesmoke;
  text-align: center;
  font-weight: bold;
}

.ant-table-filter-column-title {
  font-size: 13px;
}

.ant-table-thead>tr>.ant-table-cell-fix-left-last {
  background-color: var(--orange) !important;
  font-size: 13px;
  font-weight: bold;
}

.comment_head{
  font-size: 14px;
 
}
.mobile_sticky_header .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table{
  border: none;
}

.ant-table-thead>tr>.ant-table-cell-fix-right-first {
  background-color: var(--blue);
  font-size: 13px;
  font-weight: bold;
}

.ant-table-cell-fix-left-last .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--orange);
  border-color: var(--orange);
}
.ant-table-cell-fix-left-last .ant-checkbox:hover .ant-checkbox-inner{
  border-color: var(--orange);
}
.btn-checkbox.orange_checkbox:hover .ant-checkbox .ant-checkbox-inner{
  border-color: var(--orange);
}


.ant-table-cell-fix-right-first .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--blue);
}
.ant-table-cell-fix-right-first .ant-checkbox:hover .ant-checkbox-inner{
  border-color: var(--blue);
}

.ant-table-filter-trigger-container:hover {
  background: var(--blue);
  font-size: 13px;
}

.ant-table-filter-trigger.active {
  color: #b32d2b;
}

.ant-table-filter-trigger {
  color: #333333;

}

.ant-table-tbody>tr>td {
  border-bottom: 1px solid #f0f0f0;
  text-align: center;
}

.ant-table.ant-table-middle .ant-table-tbody>tr>td {
  font-weight: 600;
}

.btn-continue {
  border-radius: 4px;
  border: none;
  color: #333333;
  text-align: center;
  font-size: 14px;
  width: 150px;
  transition: all 0.5s;
  cursor: pointer;
  background-color: var(--blue);
  color: white;
}

.btn-continue.fixed_btn{
  position: fixed;
  bottom: 2vh;
  right: 2vw;
  z-index: 6;
}

.btn-continue svg{
  font-size: 10px;
  opacity: 0;
  transition: 0.25s ease-in-out;
}
.btn-continue:hover svg{
  opacity: 1;
  margin-left: 7px;
}
.btn-continue:hover,
.btn-continue:focus {
  background-color: var(--blue);;
  filter: brightness(1.2);
  color: white;
}

.main_page_search_bar{
  top: 0;
  border: 2px solid var(--orange);;
  border-radius: 60px;
  margin-block: 0px;
  padding-block: 5px;
  width: 80%;
}

.main_page_search_bar .search_input_main_page{
  background-color: transparent!important;
}
.main_page_search_bar svg{
  font-size: 16px;
  color: var(--blue);
}

.main_page_search_bar.mobile_search{
  padding: 0;
  padding-block: 2px;
  margin: 0;
  margin-left: 45px;
  margin-right: 5px;
  display: none;
  width: 100%;
}
.main_page_search_bar.mobile_search svg{
  color: white;
}
.main_page_search_bar.mobile_search input{
  background-color: transparent;
  color: white;
  font-size: 14px;
  margin-left: 7px;
}
.main_page_search_bar.mobile_search input::placeholder{
  color: white!important;
}


@media only screen and (max-width: 1000px) {

  .default_container_padding {
    padding-bottom: 100px;
  }
  .btn-continue.fixed_btn{
    bottom: 80px;
  }
}

.ant-table-filter-trigger.active {
  background-color: aliceblue;
}

.mobile_sticky_header .ant-table-tbody{
  display: none;
}

.mobile_sticky_header .ant-table-thead{
  position: fixed;
  top: 45px;
  left: 60px;
  right: 0px;
  width: 100%;
  z-index: 2;
  transform: translateY(-150px);
  transition: 0.25s ease-in-out;
}

.mobile_sticky_header.active .ant-table-thead{
  transform: translateY(0px);
}


.mobile_sticky_header .ant-table-thead .ant-table-cell{
  width: 12%; 
  /* I have no idea how this works but it works */
}


/* Changes In Pagination Design */

.ant-pagination-item-link{
  background-color: transparent!important;
  outline: none!important;
  border: none!important;
}
.ant-pagination-item-link svg{
  color: white;
}
.ant-pagination-prev, .ant-pagination-next{
  border-radius: 100%;
  outline: none;
  min-height: 35px!important;
  min-width: 35px!important;
  background-color: var(--blue)!important;
}
.ant-pagination-prev:hover, .ant-pagination-next:hover{
  background-color: var(--orange)!important;
}

.ant-pagination-disabled{
  background-color: var(--blue);
  opacity: 0.6;
}

.ant-pagination-item{
  border: 2px solid var(--blue)!important;
  min-height: 35px!important;
  min-width: 35px!important;
}

.ant-pagination-item > a{
  color: var(--blue);
}

.ant-pagination-item:hover, .ant-pagination-item-active{
  border: 2px solid var(--orange)!important;
}

.ant-pagination-item:hover > a, .ant-pagination-item-active > a{
  color: var(--orange)!important;
  font-weight: 500;
}

@media (max-width: 1000px) {
  
  .mobile_sticky_header .ant-table-thead .ant-table-cell{
    width: 1%; 
    /* I have no idea how this works but it works */
  }
  .mobile_sticky_header .ant-table-thead{
    left: 0;
  }
}
@media (max-width: 700px) {
  .btn-continue.fixed_btn{
    bottom: 70px;
  }

  .comment_head{
    font-size: 13px;
    color: #333333;
  }

  .category_fig{
    margin: 0 0px 0em !important ;
  }

  .icon_find > .ant-tabs > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab  {
    margin: 0 5px 0 5px !important;
  }
}
@media (max-width: 500px) {
  
  .btn-continue.fixed_btn{
    bottom: 55px;
  }
  .mobile_sticky_header .ant-table-thead{
    top: 35px;
  }
  .main_page_search_bar{
    border-radius: 60px;
    margin-block: 0px;
    padding-block: 2px;
    width: 98%;
  }

}

#number_posting{
  font-size: x-small;
}

.latest-news-resources{
  position: relative;
}

.comment_head_first{
  margin-top: 30px;
}


/* .icon_find{
  display: flex;
  justify-content: center;
} */

.category_image{
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background:rgb(141 193 235);
  padding: 5px;
}

.category_fig{
  margin: 0 10px 0em ;
}

.category_img_caption{
  font-size: x-small;
}

.icon_find > .ant-tabs {
  padding-bottom: 3px;
}

.icon_find > .ant-tabs-top > .ant-tabs-nav {
  margin: 0 !important;
}

.icon_find > .ant-tabs > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab  {
  padding: 0 !important;
}

.cat_not_found{
  margin: 0px;
}

.three-dot{
  font-size: 25px;
  position: absolute;
  right: 10px;
  cursor: pointer;
}

/* @media only screen and (min-width: 768px) {
  .ques-popup-header{
    font-size: 24px;
  }
} */

.AskQuesModal > .ant-modal-content{
  border-radius: 20px;
}

.AskQuesModal{
  width: auto !important;
}