body {
    margin-top: 0px;
    margin-left: 0px;
}

.terms_page .ant-form-item-control-input-content {
    text-align: center;
}

.terms_page .ant-form-item-control-input {
    width: 100%;
}

.RegistrationFormFooter {
    position: fixed;
    bottom: 0 !important;
    background-color: white;
    border-top: 1px solid;
    width: 100% !important;
}

.page_12 {
    margin: 0 auto !important;
}

@media only screen and (max-width:1000px) {

    .RegistrationFormFooter {
        bottom: 75px !important;
        padding-bottom: 30px;
    }
}

@media only screen and (max-width:575px) {
    .terms_page {
        height: 62vh !important;
        width: 100%;
        font: 10px 'Nirmala UI Semilight' !important;
        max-width: 100%;
        overflow: auto;
        align-items: center;
        align-content: center;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
        margin: auto auto;
        margin-bottom: 50px;
    }

    .RegistrationFormFooter {
        bottom: 55px !important;
        padding-bottom: 0px;
    }

    #page_1 {
        margin: 10px 10px 10px 10px !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    #page_1 #p1dimg1 {
        width: 100% !important;
    }

    #page_1 #p1dimg1 #p1img1 {
        width: 90% !important;
        margin: auto auto;
    }

    #page_1 #id1_1 {
        width: 100% !important;
        margin: 0px 0px 0px 45% !important;
    }

    #page_2 {
        margin: 10px 10px 10px 10px !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    #page_3 {
        margin: 10px 10px 10px 10px !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    #page_4 {
        margin: 10px 10px 10px 10px !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    #page_5 {
        margin: 10px 10px 10px 10px !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    #page_6 {
        margin: 10px 10px 10px 10px !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    #page_7 {
        margin: 10px 10px 10px 10px !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    #page_7 #p7dimg1 {
        width: 100% !important;
        white-space: break-spaces !important;
    }

    #page_7 #p7dimg1 #p7img1 {
        width: 90% !important;
        margin: auto auto;
    }

    .p49 {
        padding-left: 25% !important;

    }

    #page_8 {
        margin: 10px 10px 10px 10px !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    #page_9 {
        margin: 10px 10px 10px 10px !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    #page_10 {
        margin: 10px 10px 10px 10px !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    #page_11,
    #page_12 {
        margin: 0 auto !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    .ant-form {
        margin: 10px 10px 10px 10px;
    }

    .ant-col-offset-12 {
        margin-left: 40%;
    }
}

@media only screen and (min-width:575px) {
    .terms_page {
        height: 100%;
        width: 100%;
        font: 12px 'Nirmala UI Semilight' !important;
        max-width: 100%;
        overflow: auto;
        align-items: center;
        align-content: center;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
        margin: auto auto;
    }

    #page_1 {
        margin: 10px 10px 10px 10px !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    #page_1 #p1dimg1 {
        width: 100% !important;
    }


    #page_1 #p1dimg1 #p1img1 {
        width: 80% !important;
        margin: auto auto;
    }

    #page_1 #id1_1 {
        width: 100% !important;
        margin: 0px 0px 0px 45% !important;
    }

    #page_1 #id1_2 {
        width: 100% !important;
    }

    #page_2 {
        margin: 10px 10px 10px 10px !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    #page_3 {
        margin: 10px 10px 10px 10px !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    #page_4 {
        margin: 10px 10px 10px 10px !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    #page_5 {
        margin: 10px 10px 10px 10px !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    #page_6 {
        margin: 10px 10px 10px 10px !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    #page_7 {
        margin: 10px 10px 10px 10px !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    #page_7 #p7dimg1 {
        width: 100% !important;
    }

    #page_7 #p7dimg1 #p7img1 {
        width: 90% !important;
        margin: auto auto;
    }

    .p49 {
        padding-left: 45% !important;
    }

    #page_8 {
        margin: 10px 10px 10px 10px !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    #page_9 {
        margin: 10px 10px 10px 10px !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    #page_10 {
        margin: 10px 10px 10px 10px !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    #page_11,
    #page_12 {
        margin: 0 auto !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    /* .ant-form {
        margin: 10px 10px 10px 10px;
    } */

    .ant-col-offset-12 {
        margin-left: 40%;
    }
}

#page_1 {
    position: relative;
    overflow: hidden;
    margin: 75px 0px 83px 73px;
    padding: 0px;
    border: none;
    width: 721px;
}

#page_1 #id1_1 {
    border: none;
    margin: 0px 0px 0px 265px;
    padding: 0px;
    border: none;
    width: 456px;
    overflow: hidden;
}

#page_1 #id1_2 {
    border: none;
    margin: 32px 0px 0px 2px;
    padding: 0px;
    border: none;
    overflow: hidden;
    word-break: break-word;
}

#page_1 #p1dimg1 {
    position: absolute;
    top: 1px;
    left: 0px;
    z-index: -1;
    width: 647px;
    height: 25px;
}

#page_1 #p1dimg1 #p1img1 {
    width: 647px;
    height: 25px;
}




#page_2 {
    position: relative;
    overflow: hidden;
    margin: 75px 0px 114px 75px;
    padding: 0px;
    border: none;
    width: 719px;
}





#page_3 {
    position: relative;
    overflow: hidden;
    margin: 75px 0px 100px 75px;
    padding: 0px;
    border: none;
    width: 719px;
}





#page_4 {
    position: relative;
    overflow: hidden;
    margin: 75px 0px 94px 75px;
    padding: 0px;
    border: none;
    width: 719px;
}





#page_5 {
    position: relative;
    overflow: hidden;
    margin: 75px 0px 119px 75px;
    padding: 0px;
    border: none;
    width: 719px;
}





#page_6 {
    position: relative;
    overflow: hidden;
    margin: 75px 0px 262px 75px;
    padding: 0px;
    border: none;
    width: 719px;
}





#page_7 {
    position: relative;
    overflow: hidden;
    margin: 75px 0px 94px 73px;
    padding: 0px;
    border: none;
    width: 721px;
}

#page_7 #p7dimg1 {
    position: absolute;
    top: 1px;
    left: 0px;
    z-index: -1;
    width: 647px;
    height: 25px;
}

#page_7 #p7dimg1 #p7img1 {
    width: 647px;
    height: 25px;
}




#page_8 {
    position: relative;
    overflow: hidden;
    margin: 75px 0px 99px 75px;
    padding: 0px;
    border: none;
    width: 719px;
}





#page_9 {
    position: relative;
    overflow: hidden;
    margin: 75px 0px 107px 75px;
    padding: 0px;
    border: none;
    width: 719px;
}





#page_10 {
    position: relative;
    overflow: hidden;
    margin: 75px 0px 93px 75px;
    padding: 0px;
    border: none;
    width: 719px;
}





#page_11,
#page_12 {
    position: relative;
    overflow: hidden;
    margin: 0 auto !important;
    padding: 0px;
    border: none;
    width: 649px;
}





.ft0 {
    /*font: bold 19px 'Nirmala UI Semilight'; */
    line-height: 25px;
}

.ft1 {
    /* font: bold 11px 'Nirmala UI Semilight';*/
    line-height: 13px;
}

.ft2 {
    /* font: bold 13px 'Nirmala UI Semilight'; */
    margin-left: 38px;
    line-height: 17px;
}

.ft3 {
    /* font: bold 13px 'Nirmala UI Semilight'; */
    line-height: 17px;
}

.ft4 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 31px;
    line-height: 18px;
}

.ft5 {
    /* font: bold 13px 'Nirmala UI Semilight'; */
    line-height: 18px;
}

.ft6 {
    /* font: 13px 'Nirmala UI Semilight'; */
    line-height: 18px;
}

.ft7 {
    /* font: 13px 'Nirmala UI Semilight'; */
    line-height: 17px;
}

.ft8 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 28px;
    line-height: 18px;
}

.ft9 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 28px;
    line-height: 17px;
}

.ft10 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 33px;
    line-height: 17px;
}

.ft11 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 32px;
    line-height: 17px;
}

.ft12 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 34px;
    line-height: 17px;
}

.ft13 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 29px;
    line-height: 17px;
}

.ft14 {
    /* font: bold 12px 'Nirmala UI Semilight'; */
    line-height: 15px;
}

.ft15 {
    /* font: bold 13px 'Nirmala UI Semilight'; */
    margin-left: 36px;
    line-height: 17px;
}

.ft16 {
    /* font: 13px 'Nirmala UI Semilight'; */
    text-decoration: underline;
    line-height: 18px;
}

.ft17 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 26px;
    line-height: 18px;
}

.ft18 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 26px;
    line-height: 17px;
}

.ft19 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 32px;
    line-height: 18px;
}

.ft20 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 33px;
    line-height: 18px;
}

.ft21 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 36px;
    line-height: 17px;
}

.ft22 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 27px;
    line-height: 17px;
}

.ft23 {
    /* font: 13px 'Nirmala UI Semilight'; */
    background-color: #feffff;
    line-height: 18px;
}

.ft24 {
    /* font: 13px 'Nirmala UI Semilight'; */
    background-color: #feffff;
    line-height: 17px;
}

.ft25 {
    /* font: bold 13px 'Nirmala UI Semilight'; */
    margin-left: 37px;
    line-height: 17px;
}

.ft26 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 46px;
    line-height: 17px;
}

.ft27 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 44px;
    line-height: 17px;
}

.ft28 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 44px;
    line-height: 18px;
}

.ft29 {
    /* font: bold 13px 'Nirmala UI Semilight'; */
    margin-left: 31px;
    line-height: 17px;
}

.ft30 {
    /* font: bold 13px 'Nirmala UI Semilight'; */
    margin-left: 34px;
    line-height: 17px;
}

.ft31 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 25px;
    line-height: 18px;
}

.ft32 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 24px;
    line-height: 18px;
}

.ft33 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 23px;
    line-height: 17px;
}

.ft34 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 46px;
    line-height: 18px;
}

.ft35 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 39px;
    line-height: 17px;
}

.ft36 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 41px;
    line-height: 17px;
}

.ft37 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 39px;
    line-height: 18px;
}

.ft38 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 38px;
    line-height: 18px;
}

.ft39 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 42px;
    line-height: 17px;
}

.ft40 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 43px;
    line-height: 18px;
}

.ft41 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 41px;
    line-height: 18px;
}

.ft42 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 38px;
    line-height: 17px;
}

.ft43 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 42px;
    line-height: 18px;
}

.ft44 {
    /* font: bold 14px 'Nirmala UI Semilight'; */
    line-height: 21px;
}

.p0 {
    text-align: left;
    margin-top: 0px;
    margin-bottom: 0px;
}

.p1 {
    text-align: justify;
    padding-left: 47px;
    /* padding-right: 75px; */
    margin-top: 9px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p2 {
    text-align: justify;
    padding-left: 47px;
    /* padding-right: 76px; */
    margin-top: 7px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p3 {
    text-align: justify;
    padding-left: 47px;
    /* padding-right: 75px; */
    margin-top: 7px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p4 {
    text-align: left;
    padding-left: 47px;
    /* padding-right: 76px; */
    margin-top: 12px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p5 {
    text-align: left;
    padding-left: 47px;
    margin-top: 9px;
    margin-bottom: 0px;
}

.p6 {
    text-align: left;
    padding-left: 95px;
    /* padding-right: 75px; */
    margin-top: 9px;
    margin-bottom: 0px;
    text-indent: -48px;
}

.p7 {
    text-align: justify;
    padding-left: 95px;
    /* padding-right: 75px; */
    margin-top: 10px;
    margin-bottom: 0px;
    text-indent: -48px;
}

.p8 {
    text-align: left;
    padding-left: 47px;
    margin-top: 10px;
    margin-bottom: 0px;
}

.p9 {
    text-align: justify;
    padding-left: 47px;
    /* padding-right: 76px; */
    margin-top: 9px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p10 {
    text-align: left;
    padding-left: 47px;
    margin-top: 7px;
    margin-bottom: 0px;
}

.p11 {
    text-align: left;
    padding-left: 47px;
    /* padding-right: 76px; */
    margin-top: 9px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p12 {
    text-align: justify;
    padding-left: 47px;
    /* padding-right: 75px; */
    margin-top: 10px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p13 {
    text-align: left;
    margin-top: 6px;
    margin-bottom: 0px;
}

.p14 {
    text-align: justify;
    padding-left: 47px;
    /* padding-right: 75px; */
    margin-top: 0px;
    margin-bottom: 0px;
}

.p15 {
    text-align: left;
    margin-top: 7px;
    margin-bottom: 0px;
}

.p16 {
    text-align: left;
    padding-left: 47px;
    /* padding-right: 76px; */
    margin-top: 7px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p17 {
    text-align: justify;
    padding-left: 95px;
    /* padding-right: 76px; */
    margin-top: 9px;
    margin-bottom: 0px;
    text-indent: -48px;
}

.p18 {
    text-align: left;
    padding-left: 95px;
    /* padding-right: 75px; */
    margin-top: 7px;
    margin-bottom: 0px;
    text-indent: -48px;
}

.p19 {
    text-align: left;
    padding-left: 95px;
    /* padding-right: 76px; */
    margin-top: 8px;
    margin-bottom: 0px;
    text-indent: -48px;
}

.p20 {
    text-align: left;
    padding-left: 47px;
    /* padding-right: 76px; */
    margin-top: 14px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p21 {
    text-align: left;
    padding-left: 95px;
    /* padding-right: 76px; */
    margin-top: 0px;
    margin-bottom: 0px;
    text-indent: -48px;
}

.p22 {
    text-align: left;
    margin-top: 10px;
    margin-bottom: 0px;
}

.p23 {
    text-align: justify;
    padding-left: 47px;
    /* padding-right: 75px; */
    margin-top: 28px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p24 {
    text-align: justify;
    padding-left: 47px;
    /* padding-right: 76px; */
    margin-top: 6px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p25 {
    text-align: justify;
    padding-left: 47px;
    /* padding-right: 75px; */
    margin-top: 13px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p26 {
    text-align: justify;
    padding-left: 47px;
    /* padding-right: 75px; */
    margin-top: 8px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p27 {
    text-align: left;
    margin-top: 13px;
    margin-bottom: 0px;
}

.p28 {
    text-align: justify;
    padding-left: 47px;
    /* padding-right: 76px; */
    margin-top: 0px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p29 {
    text-align: justify;
    padding-left: 47px;
    /* padding-right: 76px; */
    margin-top: 8px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p30 {
    text-align: justify;
    padding-left: 47px;
    /* padding-right: 75px; */
    margin-top: 29px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p31 {
    text-align: left;
    margin-top: 4px;
    margin-bottom: 0px;
}

.p32 {
    text-align: left;
    padding-left: 47px;
    /* padding-right: 75px; */
    margin-top: 9px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p33 {
    text-align: justify;
    padding-left: 47px;
    /* padding-right: 75px; */
    margin-top: 5px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p34 {
    text-align: justify;
    padding-left: 47px;
    /* padding-right: 75px; */
    margin-top: 11px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p35 {
    text-align: left;
    padding-left: 47px;
    /* padding-right: 76px; */
    margin-top: 0px;
    margin-bottom: 0px;
}

.p36 {
    text-align: justify;
    padding-left: 47px;
    /* padding-right: 75px; */
    margin-top: 27px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p37 {
    text-align: left;
    margin-top: 12px;
    margin-bottom: 0px;
}

.p38 {
    text-align: justify;
    padding-left: 47px;
    /* padding-right: 76px; */
    margin-top: 9px;
    margin-bottom: 0px;
}

.p39 {
    text-align: left;
    margin-top: 11px;
    margin-bottom: 0px;
}

.p40 {
    text-align: justify;
    padding-left: 47px;
    /* padding-right: 75px; */
    margin-top: 23px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p41 {
    text-align: left;
    padding-left: 95px;
    /* padding-right: 76px; */
    margin-top: 9px;
    margin-bottom: 0px;
    text-indent: -48px;
}

.p42 {
    text-align: left;
    margin-top: 8px;
    margin-bottom: 0px;
}

.p43 {
    text-align: left;
    padding-left: 119px;
    /* padding-right: 76px; */
    margin-top: 0px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p44 {
    text-align: left;
    padding-left: 119px;
    /* padding-right: 76px; */
    margin-top: 10px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p45 {
    text-align: justify;
    padding-left: 119px;
    /* padding-right: 76px; */
    margin-top: 9px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p46 {
    text-align: left;
    padding-left: 119px;
    /* padding-right: 75px; */
    margin-top: 7px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p47 {
    text-align: left;
    padding-left: 119px;
    /* padding-right: 75px; */
    margin-top: 10px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p48 {
    text-align: justify;
    padding-left: 47px;
    /* padding-right: 76px; */
    margin-top: 8px;
    margin-bottom: 0px;
}

.p49 {
    text-align: left;
    padding-left: 167px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.p50 {
    text-align: left;
    padding-left: 2px;
    margin-top: 32px;
    margin-bottom: 0px;
}

.p51 {
    text-align: justify;
    padding-left: 49px;
    /* padding-right: 76px; */
    margin-top: 9px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p52 {
    text-align: left;
    padding-left: 49px;
    /* padding-right: 76px; */
    margin-top: 7px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p53 {
    text-align: justify;
    padding-left: 49px;
    /* padding-right: 75px; */
    margin-top: 10px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p54 {
    text-align: left;
    padding-left: 2px;
    margin-top: 11px;
    margin-bottom: 0px;
}

.p55 {
    text-align: left;
    padding-left: 49px;
    /* padding-right: 76px; */
    margin-top: 8px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p56 {
    text-align: justify;
    padding-left: 49px;
    /* padding-right: 76px; */
    margin-top: 10px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p57 {
    text-align: left;
    padding-left: 2px;
    margin-top: 7px;
    margin-bottom: 0px;
}

.p58 {
    text-align: left;
    padding-left: 2px;
    margin-top: 9px;
    margin-bottom: 0px;
}

.p59 {
    text-align: left;
    padding-left: 2px;
    margin-top: 8px;
    margin-bottom: 0px;
}

.p60 {
    text-align: left;
    padding-left: 49px;
    /* padding-right: 76px; */
    margin-top: 9px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p61 {
    text-align: justify;
    padding-left: 121px;
    /* padding-right: 76px; */
    margin-top: 8px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p62 {
    text-align: left;
    padding-left: 49px;
    margin-top: 8px;
    margin-bottom: 0px;
}

.p63 {
    text-align: left;
    padding-left: 121px;
    /* padding-right: 76px; */
    margin-top: 8px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p64 {
    text-align: justify;
    padding-left: 121px;
    /* padding-right: 75px; */
    margin-top: 10px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p65 {
    text-align: left;
    padding-left: 121px;
    /* padding-right: 75px; */
    margin-top: 11px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p66 {
    text-align: left;
    padding-left: 49px;
    margin-top: 10px;
    margin-bottom: 0px;
}

.p67 {
    text-align: justify;
    padding-left: 119px;
    /* padding-right: 75px; */
    margin-top: 0px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p68 {
    text-align: justify;
    padding-left: 119px;
    /* padding-right: 76px; */
    margin-top: 8px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p69 {
    text-align: left;
    padding-left: 119px;
    /* padding-right: 75px; */
    margin-top: 9px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p70 {
    text-align: justify;
    padding-left: 119px;
    /* padding-right: 75px; */
    margin-top: 9px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p71 {
    text-align: justify;
    padding-left: 119px;
    /* padding-right: 75px; */
    margin-top: 25px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p72 {
    text-align: justify;
    padding-left: 119px;
    /* padding-right: 75px; */
    margin-top: 7px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p73 {
    text-align: left;
    margin-top: 9px;
    margin-bottom: 0px;
}

.p74 {
    text-align: left;
    padding-left: 119px;
    /* padding-right: 75px; */
    margin-top: 8px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p75 {
    text-align: left;
    padding-left: 119px;
    /* padding-right: 76px; */
    margin-top: 8px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p76 {
    text-align: left;
    padding-left: 119px;
    /* padding-right: 76px; */
    margin-top: 9px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p77 {
    text-align: left;
    padding-left: 47px;
    /* padding-right: 76px; */
    margin-top: 0px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p78 {
    text-align: justify;
    padding-left: 47px;
    /* padding-right: 76px; */
    margin-top: 10px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p79 {
    text-align: justify;
    padding-left: 119px;
    /* padding-right: 75px; */
    margin-top: 11px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p80 {
    text-align: justify;
    padding-left: 119px;
    /* padding-right: 76px; */
    margin-top: 7px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p81 {
    text-align: left;
    padding-left: 119px;
    /* padding-right: 76px; */
    margin-top: 7px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p82 {
    text-align: justify;
    padding-left: 119px;
    /* padding-right: 76px; */
    margin-top: 10px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p83 {
    text-align: justify;
    padding-left: 119px;
    /* padding-right: 75px; */
    margin-top: 10px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p84 {
    text-align: left;
    padding-left: 47px;
    margin-top: 8px;
    margin-bottom: 0px;
}

.p85 {
    text-align: left;
    padding-left: 119px;
    /* padding-right: 75px; */
    margin-top: 0px;
    margin-bottom: 0px;
}

.p86 {
    text-align: justify;
    padding-left: 47px;
    /* padding-right: 76px; */
    margin-top: 20px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p87 {
    text-align: left;
    margin-top: 30px;
    margin-bottom: 0px;
}

.p88 {
    text-align: justify;
    padding-left: 119px;
    padding-right: 6px;
    margin-top: 0px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p89 {
    text-align: justify;
    padding-left: 119px;
    padding-right: 5px;
    margin-top: 7px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p90 {
    text-align: justify;
    padding-left: 119px;
    padding-right: 5px;
    margin-top: 8px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p91 {
    text-align: justify;
    padding-left: 119px;
    padding-right: 5px;
    margin-top: 13px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p92 {
    text-align: justify;
    margin-top: 7px;
    margin-bottom: 0px;
}