.container-three{
    display: flex;
    justify-content: space-between;
    margin: 3px 0px;
  }
  
  .container-two{
    display: flex;
    justify-content: start;
  }
  
  .container_four{
    display: flex;
    justify-content: space-between;
  }
  
  .container-five{
    margin-top: 5px;
  }
  
  .main_container{
    display: flex;
    justify-content: space-between;
  }
  
  .view_btn{
  cursor: pointer;
  }
  
  .verticalLine{
    margin-left: 20px;
    margin-right: 20px;
  }
  
  .cancel_order{
    cursor: pointer;
  }


  @media only screen and (min-width:551px) {
    .mainPendingTab{
      display: inline-block;
      justify-content: center;
      width: 70%;
    }
}

/* @media only screen and (max-width:551px) {
  .mainPendingTab{
    display: flex;
    justify-content: center;
    max-width: 100%;
  }
} */


 