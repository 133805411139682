@import '~antd/dist/antd.css';
/* @import url('https://fonts.googleapis.com/css2?family=Prompt:wght@700&display=swap'); */

.container-login{
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: .9;
}

.phone_input{
  border: 1px solid #cbddf8;
  border-radius: 3px;
}

.login-panel .login-form-forgot {
  /* float: left; */
  color: #454545;
  display: flex;
}


.login-form-forgot>div:nth-child(2) {
  /* float: right; */
}

.login-panel .login-form-forgot .ant-form-item-control-input-content {
  display: inline-flex;
  justify-content: space-around;
}

.login-panel .login-form-forgot .ant-form-item-control-input-content div:nth-child(2) {
  /* margin-left: 105px; */
  color: var(--orange);
}



.ant-col-rtl .login-form-forgot {
  float: left;
}

.login-form-button {
  width: 100%;
}

.help-container{
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 1px 20px 10px 0px;
  cursor: pointer;
}

.help-text{
  font-size: small;
}

.ant-modal-mask{
  background-color: rgba(0, 0, 0, 0);
}
.login-close-btn{
  border-radius: 5px;
  border: none;
  color: #333333;
  text-align: center;
  font-size: 14px;
  width: 150px;
  transition: all 0.5s;
  cursor: pointer;
  background-color: var(--blue);
  color: white;
}
.login-close-btn:hover{
  background-color: var(--blue);
  color: white;
}


@media (min-width:700px) {
  .login-form {
    max-width: 300px !important;
    position: absolute;
    top: 40% !important;
    left: 47% !important;
    transform: translate(-70%, -150%);
  }
  
  .login-form-otp {
    max-width: 300px !important;
    position: absolute;
    top: 48% !important;
    left: 47% !important;
    transform: translate(-50%, -50%);
  }

  .top-login{
    background-image: url('../../images/labour_bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 0%;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--blue);
  }

.login-panel {
  position: absolute;
  top: 49%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  height: 550px !important;
  margin-top: 10px;
  background: rgb(255, 255, 255);
   border-radius: 15px;
  box-shadow: rgba(200, 200, 200, 0.63) 2px 2px 7px 2px;
  padding: 30px 5%;
}

.login-panel-otp{
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  height: 635px;
  margin-top: 10px;
  background: rgb(255, 255, 255);
   border-radius: 15px;
  box-shadow: rgba(200, 200, 200, 0.63) 2px 2px 7px 2px;
  padding: 30px 5%;
}
}
.login-panel:focus {
  position: absolute;
  top: 49%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  height: 600px;
  border-radius: 15px; 
  box-shadow: rgba(200, 200, 200, 0.63) 2px 2px 7px 2px;
  padding: 30px 5%; 
}


.signup {
  position: absolute;
  top: 49%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background: rgb(255, 255, 255);
  border-radius: 8px !important;
  border: 1px solid var(--orange);
}



.mpinc {
  width: 50px;
}

.login-form-button {
  background: var(--orange);
  border-color:var(--orange);
  color: white;
}


.login-form-button:focus {
  background: var(--orange);
  border-color: var(--orange);
  color: white;
}

/* .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #6fd4b1;
    border-color: #6fd4b1;
  } */

.ant-form-item-explain.ant-form-item-explain-error {
  color: #3f260d;
}

.ant-form-item-has-error .ant-input-affix-wrapper {
  border-color: var(--orange);
}

.ant-form-item-control-input-content {
  text-align: left;
}

.ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
  width: 35vw;
}


/* CSS for mobile */
@media (max-width: 700px) {
  .ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
    width: 85vw;
  }


  .login-form {
    max-width: 300px;
    position: absolute;
    top: 41% !important;
    left: 48% !important;
    margin: 1% !important;
    transform: translate(-50%, -50%);
  }

  .login-form-otp {
    max-width: 300px;
    position: absolute;
    top: 50% !important;
    left: 48% !important;
    margin: 1% !important;
    transform: translate(-50%, -50%);
  }

  .help-container{
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 1px 20px 10px 0px;
    cursor: pointer;
  }
  
  .top-login{
    background-image: url('../../images/labour_bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 50%;
    border-bottom-left-radius: 20%;
    border-bottom-right-radius: 20%;
    background-color: var(--blue);
  }

  .login-panel {
    top: 54%;
    left: 47%;
    position: absolute;
    transform: translate(-50%, -40%);
    width: 330px;
    min-height: 500px;
    margin-left: 2%;
    background: rgb(255, 255, 255);
     border-radius: 15px;
    box-shadow: rgba(200, 200, 200, 0.63) 2px 2px 7px 2px;
    padding: 3% 5%;
  }

  .login-panel-otp{
    top: 50%;
    left: 47%;
    position: absolute;
    transform: translate(-50%, -40%);
    width: 330px;
    min-height: 590px;
    margin-left: 2%;
    background: rgb(255, 255, 255);
     border-radius: 15px;
    box-shadow: rgba(200, 200, 200, 0.63) 2px 2px 7px 2px;
    padding: 3% 5%;
  }

  .signup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    border: 1px solid var(--orange);
    background: rgb(255, 255, 255);
    border-radius: 8px;
    box-shadow: rgba(200, 200, 200, 0.63) 2px 2px 7px 2px;
  }
}

@media (max-width: 330px) {
  .login-panel {
    top: 50%;
    left: 47%;
    position: absolute;
    transform: translate(-50%, -50%);
    width: 300px;
    height: 520px;
    margin-top: 1px;
    margin-left: 2%;
    background: rgb(255, 255, 255);
     border-radius: 15px;
    box-shadow: rgba(200, 200, 200, 0.63) 2px 2px 7px 2px;
    padding: 3% 5%;
  }

}

#employer{
  color: var(--orange);
}

#proffessional{
  color: var(--blue);
}

#welcome-txt{
  font-size: 20px;
}

.tour_popup_modal > .ant-modal-content{
  border-radius: 20px;
}

