.App {
  text-align: center;
  width: 100%;
}

::-webkit-scrollbar {
  width: 0.6vw;
  height: 0px;
}

::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0);
}

::-webkit-scrollbar-thumb {
  background-color: var(--blue);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--orange);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.highlight{
  color: var(--orange);
}

@media only screen and (min-width:551px) {
  .App_class {
    flex-direction: row-reverse;
  }
}


/* Make direction as column to make the menu bar to appear at the bottom. */
@media only screen and (max-width:550px) {
  .App {
    max-height: 92vh;
  }

  .App_class {
    flex-direction: column;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

*:fullscreen,
*:-webkit-full-screen,
*:-moz-full-screen {
  background-color: #FFF !important;
  ;
}

::backdrop {
  z-index: 0;
  background-color: white !important;
}