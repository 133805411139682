.tour-confirm > .ant-modal-content{
    border-radius: 20px;
    background-color: rgb(255, 141, 26);
  }

  .tour-confirm{
    width: auto !important;
  }

  .takeTourBtn{
    margin-right: 10px;
  }

  .lang-btn{
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 15px;
  }

  .radio-btn{
    /* margin: 10px; */
    background: none;
    text-align: center;
    display: inline;
  }

  .radio-btn:hover{
   background-color: white;
  }

  .lang-btn > .ant-radio-group > .ant-radio-button-wrapper{
    border-radius: 10px ;
    height: 32px;
    /* padding: 4px 15px !important; */
  }

  .lang-btn > .ant-radio-group > .ant-radio-button-wrapper:not(:first-child)::before {
    content: none !important;
  }