.congrats-text{
    position: relative;
    bottom: 150px;
}

.congrats-text > h2{
    font-size: 30px !important;
}

.continue-dash-btn {
    height: auto !important;
    padding: 10px !important;
    color:var(--blue) !important;
    border: 2px solid var(--blue) !important;
    padding: 10px !important;
    border-radius: 30px !important;
}


/* .main-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .animatedContainer {
    width: 100%;
    max-width: 600px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  
  .congrats-text {
    width: 100%;
    max-width: 400px;
    margin-top: 60px;
  } */

  

  /* media screen for tablet and laptop */
  @media screen and (min-width: 601px) {
    .main-container{
      position: relative;
      bottom: 100px;
    }
  }
  
  @media screen and (max-width: 600px) {
    /* .animatedContainer {
      margin-right: 0;
    } */

    .animatedContainer>img{
        height: 300px;
    }

    .continue-dash-btn {
       margin-top: 10px !important;
    }

    .congrats-text{
        position: relative;
        bottom: 85px;
    }

    /* .congrats-text {
        width: 100%;
        max-width: 400px;
      } */
   
    
    .congrats-text {
      margin-top: 20px;
    }
  }