@import '~antd/dist/antd.css';
/* @import url('https://fonts.googleapis.com/css2?family=Prompt:wght@700&display=swap'); */


.container{
  color: black;
    width: 100%;
    position:absolute !important;
    bottom: 1% !important;
    align-content: center !important;
  }

  @media (max-width: 700px) {
    .container{
      color: black;
      width: 100%;
      margin-left: -5%;
      position:absolute !important;
      bottom: 0% !important;
      align-content: center !important;
    }

    .version{
      position:relative !important;
      color: black;
      text-align: center;
      font-size: 10px;
    }
    .company{
      color:black;

      font-size: 12px;
    }
  }