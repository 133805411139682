.ant-menu-item-selected {
    background-color: var(--orange) !important;
}


.ant-radio-button-wrapper {
    border: none !important;
    box-shadow: none !important;
    padding: 10px !important;
    }
    
    .ant-form label{
      font-size: small !important;
    }
    
    .ant-radio-button-wrapper:not(:first-child)::before {
      position: absolute;
      top: -1px;
      left: -1px;
      display: block;
      box-sizing: content-box;
      width: 1px;
      height: 100%;
      padding: 1px 0;
      background-color: #fff !important;
      transition: background-color 0.3s;
      content: '';
    }
