.running-data-category{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: var(--orange);
    color: var(--blue);
    padding: 3px;
}

.running-data-login{
    position: absolute;
    top: 0;
    left: 0;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    right: 0;
    background-color: var(--orange);
    color: var(--blue);
    padding: 3px;
}
.running-data-container{
    /* background-color: #ff8d1a; */
    border-radius: 5px;
    margin-right: 12px;
    padding: 1px;
}

