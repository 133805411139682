.faq-heading{
    font-size: 24px;
    font-weight: 500;
    margin-top: 0;
    background: var(--blue);
    color: #fff;
    display: block;
    width: 50%;
    padding: 1px 5px 2px 15px;
    border-radius: 100px;
}

.faq-main-collapse{
    width: 800px;
    text-align: center;
}

.faq-main-collapse .ant-collapse-header-text{
    font-size: 17px;
    width:auto;
    font-weight: 700;
    text-align: initial;
}

.collapse-text{
    color: #888;
    font-size: 15px;
    line-height: 1.8;
    margin-bottom: 10px;
    padding-left: 0;
    position: relative;
    text-align: left;
}
.faq-main-collapse .ant-collapse-item-active{
    background-color: aliceblue;
}
.faq-main-collapse .ant-collapse-header{
    margin-top: 25px;
}
@media only screen and (max-width: 767px) { 
    .faq-heading{
        font-size:18px;
        font-weight: 500;
        margin-top: 0;
        background: var(--blue);
        color: #fff;
        display: block;
        padding: 1px 5px 2px 15px;
        border-radius: 100px;
        width: 100%;
    }
    .faq-main-collapse .ant-collapse-header-text{
        font-size: 14px;
        width:auto;
    }
    .collapse-text{
        color: #888;
        font-size: 13px;
        line-height: 1.8;
        margin-bottom: 10px;
        padding-left: 0;
        position: relative;
        text-align: left;
    }
    .faq-main-collapse{
        width: 100% !important
    }
}

