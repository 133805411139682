@import "~antd/dist/antd.css";
/* h4 {
    margin-bottom: 16px;
} */

.requirement-container {
  /* margin: 20px; */
  /* overflow-x: visible; */
  /* height: 85vh; */
  text-align: -webkit-center;
  /* overflow-y: auto; */
  /* margin-bottom: 50px; */
  -ms-overflow-style: none;
  /* IE and Edge */
  /* scrollbar-width: none; */
  /* Firefox */
}

/* .ant-collapse-content-box > .ant-row {
  justify-content: space-between;
} */

.priceCap {
  position: absolute;
  left: 10px;
  bottom: 1px;
}

.requirement-container::-webkit-scrollbar {
  display: none;
}

.confirm-btn {
  float: right;
}

.FormItem {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.hiring-style > div > label {
  color: var(--orange) !important;
  font-weight: bold;
}

.buying-style > div > label,
.tradesman-title .ant-modal-title {
  color: var(--blue) !important;
  font-weight: bold;
}

.hiring-city-style.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: var(--orange) !important;
  outline: 0;
  border-right-width: 1px !important;
  -webkit-box-shadow: 0 0 0 2px rgba(255, 150, 0, 0.26);
  box-shadow: 0 0 0 2px rgba(255, 150, 0, 0.26);
}

.hiring-focus-style:focus,
.hiring-focus-style:hover {
  border-color: var(--orange) !important;
  outline: 0;
  border-right-width: 1px !important;
  -webkit-box-shadow: 0 0 0 2px rgba(255, 150, 0, 0.26);
  box-shadow: 0 0 0 2px rgba(255, 150, 0, 0.26);
}

.requirement-panel > .ant-collapse-header .ant-collapse-extra {
  display: flex !important;
}

.add-container {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 4px 4px 4px 4px;
}

.upload-text {
  text-align: center;
  color: white;
}

.add-text {
  text-align: center;
  color: white;
}

.upload-text > .ant-input {
  box-sizing: content-box !important;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-block;
  width: 80%;
  min-width: 0;
  padding: 4px 2px;
  color: var(--blue);
  font-size: 14px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 10px !important;
  transition: all 0.3s;
}

@media only screen and (min-width: 551px) {
  .RequirmentCollapse {
    width: 70%;
  }
  .collapse-div {
    display: flex;
    justify-content: center;
  }
  /* .ant-form-vertical > .RequirmentCollapse{
        border: none !important;
    } */
}

@media screen and (max-width: 700px) {
  .add-container {
    width: 100%;
    display: fle;
    align-content: center;
    justify-content: center;
    padding: 4px 4px 4px 4px;
    justify-content: flex-start;
    flex-direction: row; /*This solves everything*/
  }

  .ant-table-thead > tr > th {
    /* color: #7d7272; */
    /* background: #3071b9 !important; */
    font-size: 12px;
    padding: 16px;
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.3s !important;
    font-size: 12px;
  }
}
.requirement-container
  .submit-button
  .ant-form-item-control:first-child:not([class^="ant-col-"]):not(
    [class*=" ant-col-"]
  ) {
  width: 100%;
}

.file-upload-button {
  padding: 10px;
  background-color: white;
  border: 0;
}

@media only screen and (min-width: 551px) {
  .file-upload-button {
    max-width: 880px;
  }
  .requirement-panel
    > .ant-collapse-content
    > .ant-collapse-content-box
    > .ant-row
    > .ant-col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.submit {
  background-color: #265090;
  color: white;
}

.cancel {
  background-color: #265090;
  color: white;
}

.submit:focus {
  background-color: #265090;
  color: white;
}

.cancel:focus {
  background-color: #265090;
  color: white;
}

.upload-btn {
  margin-bottom: 5px;
  float: left;
  margin: 2px;
  height: 50px;
  width: 25%;
}

.adddata-btn {
  margin-bottom: 5px;
  float: left;
  margin: 5px;
  height: 50px;
  width: 30%;
}

@media only screen and (max-width: 330px) and (min-width: 120px) {
  .upload-btn {
    margin-bottom: 5px;
    float: left;
    margin: 2px;
    height: 50px;
    width: 24%;
  }

  figcaption {
    font-size: 10px;
  }
}

@media only screen and (max-width: 400px) and (min-width: 330px) {
  .upload-btn {
    margin-bottom: 5px;
    float: left;
    margin: 2px;
    height: 50px;
    width: 25%;
  }

  figcaption {
    font-size: x-small;
  }
}

@media only screen and (min-width: 551px) {
  .ant-menu-dark.ant-menu-horizontal {
    display: none;
  }

  .ant-menu-dark.ant-menu-inline,
  .ant-menu-dark.ant-menu-vertical,
  .ant-menu-dark.ant-menu-vertical-left,
  .ant-menu-dark.ant-menu-vertical-right {
    border-right: 0;
    height: 100vh;
  }

  .ant-menu-inline-collapsed:not(.ant-layout-sider-children > ul) {
    min-width: 50px !important;
    max-width: 50px !important;
  }
}

@media only screen and (max-width: 550px) {
  .ant-menu-inline-collapsed:not(.ant-layout-sider-children > ul) {
    display: none;
  }

  .ant-menu-dark.ant-menu-horizontal {
    height: 55px;
    display: flex;
    align-content: center;
    justify-content: space-evenly;
  }

  .ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
  .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
    height: 55px;
  }

  .ant-menu-item .ant-menu-item-icon {
    margin-right: 0px;
  }
}

.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  font-size: 1.4rem;
  font-weight: bold;
  vertical-align: -webkit-baseline-middle;
}

.ant-menu-dark > .ant-menu-item:hover {
  background-color: var(--orange) !important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: var(--orange) !important;
}

.ant-menu-item-selected {
  background-color: var(--orange) !important;
}

.ant-menu.ant-menu-dark {
  background: var(--blue) !important;
}

.ant-btn {
  border-color: #fff;
}

.ant-input-affix-wrapper {
  border: none;
}

.ant-input-affix-wrapper-focused {
  border: none;
  background-color: #fff;
  box-shadow: none;
}

.ant-input-affix-wrapper-hover {
  border: none;
  background-color: #fff;
  box-shadow: none;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: none !important;
}

@media screen and (max-width: 895px) {
  .imageUploadContainer
    > .ant-upload-list-picture-card
    > .ant-upload-select-picture-card {
    height: 55px !important;
    width: 55px !important;
  }

  .imageUploadContainer
    > .ant-upload-list-picture-card
    > .ant-upload-list-picture-card-container {
    height: 55px !important;
    width: 55px !important;
  }

  .uploadImages > .ant-col-sm-4{
    max-width: 50px !important;
   }
   .uploadImages > .ant-col-xs-4{
    max-width: 50px !important;
   }

   .ant-upload-list-item-info
  > span
  > div[class="ant-upload-list-item-thumbnail"] {
  visibility: hidden !important;
   }
}

.upload_site_images_label {
  color: var(--orange) !important;
  font-weight: bold;
}

.upload_site_images_label_seller{
  color: var(--blue) !important;
  font-weight: bold;
}

.input-field-images >  .ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
  width: 100% !important;
}

.upload-row {
  margin-bottom: 10px;
}

@media screen and (min-width: 896px) {
    .uploadImages > .ant-col-md-4{
        max-width: 120px !important;
    }
}

.warningStyle{
  position: relative;
  bottom: 20px;
  /* right: 100px; */
}

.ant-upload-list-picture-card .ant-upload-list-item-actions{
  opacity: 1;
  color: rgb(30, 255, 0) !important;
  /* background-color: #265090; */
}