.userInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px 20px;
    font-size: 1.3rem;
    padding-top: 10px;
}

.userInfo__name .Name {
    font-weight: bold;
}

.page_heading {
    position: fixed;
    top: 0;
    left: 60px;
    right: 0;
    background-color: var(--blue);
    font-size: 22px;
    color: whitesmoke;
    padding-block: 5px;
    margin-bottom: 15px;
    font-weight: 600;
    height: 45px;
    z-index: 3;
}
/* Added to remove white space at left corner of page heading */
.page_heading::after{
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    height: 45px;
    width: 60px;
    background-color: var(--blue);
}

.userInfo__name .ID {
    font-size: small;
}

.profile .profilePicture {
    border-radius: 100%;
    height: 80px;
    width: 80px;
    background-color: black;
}

.ProfileDetails .heading {
    margin: 20px;
    display: flex;
    align-content: flex-end;
    justify-content: space-between;
    align-items: center;
    padding: 0px 5px;
}

.ant-btn {
    border-radius: 10px;
}

.Details {
    margin: 3%;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
}


.Logout {
    position: relative;
    /* right: 2vw;
    left: 40%; */
    background-color: var(--orange);
    color: white;
    width: fit-content;
    padding: 5px 7px;
    font-size: 12px;
    cursor: pointer;
    transition: 0.5s ease-in-out;
    /* display: none; */
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.profile_Card{
    border-start-end-radius: 40px !important;
    border-start-start-radius: 40px !important;
    display: none;
    /* margin: 0px !important;
    border-top: solid rgba(230, 228, 228, 0.85);;
    border-bottom: none;
    box-shadow:  0px -1px 1px rgb(72 72 72 / 75%); */
}


.logNEdit{
    display: flex;
    justify-content: space-evenly;
}

.headContainer{
    /* background-color: rgba(202, 200, 200, 0.7); */
    background-color: white;

}

.fa-envelope {
    position: relative;
    top: 3px;
}

.Logout:hover {
    filter: brightness(1.15);
}

.userInfo__profileImage {
    position: relative;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.userInfo__profileImage img {
    width: 100px;
    height: 100px;
    border-radius: 100%;
}

.profile_image_delete {
    margin-top: 10px;
    font-size: 13px;
    display: grid;
    place-items: center;
    background-color: var(--blue);
    color: white;
    display: flex;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
}

.profile_image_delete svg {
    font-size: 14px;
    margin-right: 7px;
}

.profilePictureModalText {
    text-align: center;
    border: 2px dashed rgba(171, 170, 170, 0.7);
    padding: 50px;
    font-size: 18px;
    font-weight: 600;
    border-radius: 10px;
    cursor: pointer;
}

.profilePicturePreview {
    max-height: 330px;
}

.selfiePicContainer {
    display: grid;
    place-items: center;
    margin-inline: 10px;
    border-top: 2px solid rgba(171, 170, 170, 0.7);
    padding-block: 20px;
    margin-top: 20px;
}

.photoTakerVideoCont {
    display: grid;
    place-items: center;
}

.photoTakerVideo {
    height: 80%;
    width: 100%;
}

.takePhotoButton {
    margin-top: 10px;
    background-color: var(--orange);
    border-radius: 100%;
    color: white;
    height: 60px;
    width: 60px;
    display: grid;
    place-content: center;
    font-size: 26px;
    cursor: pointer;
}

.current_profile_pic_sec {
    padding-bottom: 20px;
    margin: 10px;
    margin-bottom: 20px;
    border-bottom: 2px solid rgba(171, 170, 170, 0.7);
}
.profile_picture_modal .ant-modal-body{
    padding: 10px;
}
.picture_size_message{
    text-align: center;
    color: var(--orange);
    margin-top: 10px;
    font-weight: 500;
    font-size: 14px;
}
.edit_profile_btn{
    position: absolute;
    top: 40px;
    right: 25px;
    padding: 5px 7px;
    font-size: 14px;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    filter: brightness(1.1);
    transition: 0.25s ease-in-out;
}

.edit_profile_btn_mob{
    color: white;
    position: relative;
    right: 2vw;
    left: 40%;
    background-color: var(--orange);
    color: white;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px 7px;
    font-size: 12px;
    cursor: pointer;
    transition: 0.5s ease-in-out;
    /* display: none; */
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    text-align: center;
    display: flex;
}

.logoutIcon{
    /* border-radius: 16px; */
    background: var(--orange);
    /* padding: 5px; */
    font-size: 15px;
    height: 20px;
    width: 20px;
    margin-top: 5px;
}

.editIcon_mob{
    background: var(--orange);
    /* padding: 5px; */
    font-size: 15px;
    height: 20px;
    width: 20px;
    margin-top: 5px;
}

.edit_caption{
    position: absolute;
    color: black;
    top: 35px;
    width: max-content;
    margin-top: 2px;
}

.logout_caption{
    position: absolute;
    color: black;
    top: 35px;
    left: 0px;
    width: max-content;
    margin-top: 2px;

}

.editIcon{
    border-radius: 16px;
    background: var(--orange);
    padding: 5px;
    font-size: 17px;
    height: 30px;
    width: 30px;
    margin-top: 5px;
   
}
.edit_profile_btn:hover{
    filter: brightness(1.2);
}

.verifiedBtn{ 
    margin-left: 5px; 
}

@media screen and (max-width: 1000px) { 

    /* .Logout{
        display: block;
    } */
    .profile_Card{
        display: block; 
    }
}

@media screen and (max-width: 1000px) { 

    .edit_profile_btn{
        display: none; 
    }
}

@media screen and (max-width: 500px) { 
    
    .page_heading {
        left: 0;
        right: 0;
        font-size: 18px;
        text-align: start;
        padding-left: 45px;
        padding-block: 5px;
        font-weight: 500;
        height: 40px;
        z-index: 3;
    }
    .page_heading::after{
        display: none;
    }
    .edit_profile_btn{
        font-size: 12px;
    }
    .userInfo{
        margin: 0px !important;
    }
}