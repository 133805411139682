.contactus-sub-conatiner {
    text-align: left;
    margin-top: 4em;
}

.contactus-sub-conatiner>div>span {
    font-size: 20px;
    font-weight: bolder;
    font-family: sans-serif;
    margin-left: 7%;
}

.contact-content-container {
    display: flex;
    align-items: center;
    margin: 2% 10% 0 10%;
}

.contact-content-sub-container {
    margin-left: 5%;
    word-break: break-word;
}

.contact-content-sub-container p {
    margin-bottom: 2px;
    font-family: 'Nirmala UI Semilight';
    font-size: 16px;
}

@media screen and (max-width:760px) { 
    .contact-content-sub-container p {
        font-size: 14px;
    }
}