
.container-one {
  display: flex;
  justify-content: space-between;
}

.container-two {
  display: flex;
  justify-content: start;
  margin: 3px 0px;
}

.unit_part {
  margin-left: 50px;
}

.itemRate {
  font-size: 14px;
}

.econtract_rating {
  display: flex;
  justify-content: flex-start;
}

.verticalLine {
  border-left: thin solid #807d7d;
}

.container-third {
  margin-top: 15px;
}

.econtract {
  cursor: pointer;
}

.rating {
  cursor: pointer;
}

.BuyerContract {
  font-size: 16px;
  color: var(--orange) !important;
  margin-right: 8px !important;
}

.SellerContract {
  font-size: 16px;
  color: var(--blue) !important;
  margin-right: 8px !important;
}

.dashboardCompleted_Card {
  box-shadow: 0 8px 6px -6px black;
}

.rating_modal > .ant-modal-content {
  border-radius: 20px;
  /* box-shadow: rgba(0, 0, 0, 0) 0px 5px 15px !important;
  background-color: rgb(140, 179, 238) !important; */
}
.feedback_text {
  font-size: medium;
  font-weight: 500;
}

.category_icon {
  font-size: 40px;
}

/* CSS for mobile */

@media only screen and (min-device-width: 320px) and (max-device-width: 741px) {

  .unit_part {
    margin-left: 20px;
  }
  .quantity {
    font-size: 12px;
  }
  
  .category_unit {
    font-size: 12px;
  }
  
}

@media only screen and (min-width:551px) {
  .mainCompletedTab{
    display: inline-block;
    justify-content: center;
    width: 70%;
  }
}