.aboutus-content-container {
    margin: 7% 7% 0 7%;
    text-align: justify;
    word-break: break-word;
    font-family: 'Nirmala UI Semilight';
}

.aboutus-title {
    font-weight: 700;
    font-size: 21px;
    color: var(--orange);
}

.aboutus-subcontent {
    display: flex;
    margin-top: 25px;
}

.aboutus-details {
    font-size: 18px;
    word-break: break-word;
    margin-left: 30px;
}

.aboutus-layer-content {
    margin-top: 5%;
}

.custom-border {
    border-bottom: 1px dashed dimgrey;
    padding-bottom: 10px;
}

.aboutus-sublayer-heading {
    font-weight: 700;
    font-size: 31px;
    color: #666;
}

.aboutus-sublayer-heading p {
    margin-bottom: 0;
}

.aboutus-sublayer-content {
    font-size: 18px;
    word-break: break-word;
}

.aboutus-sublayers {
    display: flex;
    text-align: left;
}

.aboutus-sublayer {
    margin: 0 2% 0 2%;
    width: 50%;
}

.aboutus-company-info-container {
    display: flex;
    margin-top: 7%;
}

.aboutus-company-info {
    margin-right: 5%;
}

.aboutus-company-info p {
    text-align: center;
}

@media screen and (max-width:760px) { 
    .aboutus-content-container {
        margin: 5% 5% 0 5%;
    }

    .aboutus-subcontent {
        display: block;
    }

    .aboutus-details {
        font-size: 16px;
        margin-left: 0;
    }

    .aboutus-sublayers {
        display: block;
        text-align: justify;
    }
    
    .aboutus-sublayer {
        margin: 0;
        width: 100%;
    }

    .aboutus-sublayer-heading {
        display: flex;
        font-size: 20px;
    }

    .aboutus-layer-content {
        margin-top: 2%;
    }

    .aboutus-company-info-container {
        margin-top: 2%;
        flex-direction: column;
        align-items: center;
    }
}