h2{
    font-size: 15px; 
}

.rat_modal_text{
    font-size: 12px;
}

.rating_modal > .ant-modal-content > .ant-modal-body{
    /* height: 300px; */
    overflow: scroll;
    height: auto;
    /* height: 90%; */
    /* max-height: 350px; */
}

Button{
    width: fit-content;
}

.feedbackText{
    font-size: 22px;
}

.feedbackHead{
    margin: 15px;
    display: flex;
    justify-content: space-between;
}

.closeIcon{
    cursor: pointer;
    color: #fff;
    border-radius: 10px;
    background: #9f9ba3;
    padding: 3px;
    font-size: 17px;
    height: 20px;
    width: 20px;
    margin-top: 5px;
}

.SubmitFeedback{
    display: block;
    width: 100%;
    border: none;
    background-color: var(--orange) !important;
    color: #3f260d !important;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 10px;
    /* text-align: center; */
}
/* .SubmitFeedback:hover{
    background-color: #ff8d1a;
    color: #3f260d;
} */

.FeedbackDiv{
    margin: 0px 15px;
}

.wrongHeader{
    font-weight: bolder;
    font-size: 20px;
    margin-left: 15px;
}

hr {
    border: 0;
    border-top: 1px solid #f3f1f1;
  
}

.numDeployed{
    margin-top: 20px;
    font-size: 15px;
}

.SheetContainer > .react-modal-sheet-container{
    /* max-height: 598px; */
    overflow: scroll;
    border-top-right-radius: 30px !important;
    border-top-left-radius:30px !important;
    max-height: calc(100% - env(safe-area-inset-top) - 44px) !important;
    height: auto !important;
}

.SheetContainer > .react-modal-sheet-container::-webkit-scrollbar {
    display: none;
  }

  .starText > ul{
    position: relative;
    top: -7px;
  }


.connectBtnActive{
    background-color: var(--orange) !important;
    border-color: var(--orange) !important;
    color: white !important;
    margin-right: 20px !important;
}

.connectBtn{
    color: var(--blue) !important;
    border-color: var(--blue) !important; 
    margin-right: 20px !important;
}

.starText{
    display: flex;
    justify-content: space-between;
}
/* 
.DeployedRate{
    display: flex;
    justify-content: center;
} */

/* media queries for laptop screen */

@media (min-width:1025px){
    .SheetContainer > .react-modal-sheet-container{
        /* overflow: scroll; */
        width: 50% !important;
        height: calc(100% - env(safe-area-inset-top) - 44px) !important;
        margin-left: 25%;
    }
    select{
        width: auto;
    }

}


/* media query for mobile device */
@media (max-width: 640px) {
    .starText{
        font-size: 12px;
    }
    .SheetContainer > .react-modal-sheet-container{
        overflow: scroll; 
    }
}
