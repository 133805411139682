.not_connected {
    background-color: #d7dbdb;
}

.maybeTrademenDeployed{
    background-color: #d7dbdb;
}

.noTrademenDeployed{
    background-color: #d7dbdb;
}

.connectBtnActive{
    background-color: var(--orange) !important;
    border-color: var(--orange) !important;
    color: white !important;
    margin-right: 20px !important;
}

.connectBtn{
    color: var(--blue) !important;
    border-color: var(--blue) !important; 
    margin-right: 20px !important;
}

/* 
.ant-rate {
    font-size: 30px;
} 
*/