:root {
    --orange: #ff8000;
    --blue: #007AFF;
}

.nav_bar {
    position: fixed;
    background-color: var(--blue);
    top: 0;
    left: 0;
    bottom: 0;
    width: 16%;
    max-width: 260px;
    width: 240px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    z-index: 3;
    transition: 0.25s ease-in-out;
}

.nav_bar.hidden {
    display: none;
}

.nav_bar>a {
    margin: 0;
    padding: 0;
}

.nav_bar .nav_ele {
    position: relative;
    display: flex;
    align-items: center;
    padding: 12px 20px;
    cursor: pointer;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    /* border-top-right-radius: 50px;
    border-bottom-right-radius: 50px; */
    width: 100%;
    transition: 0.15s ease-in;
}

.nav_bar a:hover .nav_ele,
.nav_bar .nav_ele.active {
    color: black;
    background-color: var(--orange);
}

.nav_bar .nav_ele svg {
    color: whitesmoke;
    font-size: 22px;
}

.nav_bar .nav_ele .text {
    height: 100%;
    width: 100%;
    margin-left: 30px;
    overflow: hidden;
    white-space: nowrap;
    transition: 0.5s ease-in-out;
    color: white;
}

.nav_bar.active {
    width: 60px;
    background-color: var(--blue);
}

.nav_bar.active .text {
    height: 0;
    width: 0%;
    opacity: 0;
}

.website_brand .logo {
    height: 50px;
    width: 50px;
    margin: 0;
    background-image: url(icon-192x192.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: fixed;
    top: 5px;
    left: 5px;
    transform: scale(1.06);
    transition: 0.25s ease-in-out;
    z-index: 5;
}

.nav_bar .logout {
    position: absolute;
    bottom: 10px;
    left: 10px;
    font-size: 24px;
    padding: 10px;
    cursor: pointer;
    color: white;
    display: flex;
    align-items: center;
}

.nav_bar .logout .text {
    height: 100%;
    width: 100%;
    margin-left: 20px;
    overflow: hidden;
    white-space: nowrap;
    transition: 0.5s ease-in-out;
    color: white;
    font-size: 16px;
}

.mob_nav{
    height: 70px;
    position: fixed;
    bottom: 0px;
    left: 0;
    right: 0;
    background-color:var(--blue);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 5;
}
.mob_nav .mob_ele .icon .unread_message_alert{
    position: absolute;
    top: 25%;
    right: -5px;
    height: 9px;
    width: 9px;
    border-radius: 100%;
    background-color: var(--error);
}
.mob_nav ul{
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;
}
.mob_nav ul li{
    position: relative;
    width: 70px;
    height: 70px;
    z-index: 6;
    margin-inline: 5px;
}
.mob_nav ul li a{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    text-align: center;
    font-weight: 500;
}
.mob_nav ul li a .icon{
    position: relative;
    display: block;
    line-height: 75px;
    font-size: 26px;
    text-align: center;
    transition: 0.5s ease-in-out;
    color: white;
}
.mob_nav ul li.dp a .icon{
    height: 70px;
    display: flex;
    align-items: center;
}
.mob_nav ul li a .icon .dp_img{
    height: 50px;
    width: 50px;
    border-radius: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.mob_nav ul li.active a .icon{
    transform: translateY(-35px);
}
.mob_nav ul li a .text{
    position: absolute;
    color: white;
    font-weight: 500;
    letter-spacing: 0.05em;
    opacity: 0;
    transform: translateY(30px);
    transition: 0.5s ease-in-out;
}
.mob_nav ul li.active a .text{
    opacity: 1;
    transform: translateY(10px);
}
.mob_nav ul .indicator{
    opacity: 0;
    position: absolute;
    top: -50%;
    height: 70px;
    width: 70px;
    background: var(--orange);
    border-radius: 100%;
    transition: 0.4s ease-in-out;
}   
.mob_nav ul li.active ~ .indicator{
    opacity: 1;
}
.mob_nav ul li:nth-child(1).active ~ .indicator{
    transform: translateX(calc(80px * 0 + 5px));
}
.mob_nav ul li:nth-child(2).active ~ .indicator{
    transform: translateX(calc(80px * 1 + 5px));
}
.mob_nav ul li:nth-child(3).active ~ .indicator{
    transform: translateX(calc(80px * 2 + 5px));
}
.mob_nav ul li:nth-child(4).active ~ .indicator{
    transform: translateX(calc(80px * 3 + 5px));
}
.mob_nav ul li:nth-child(5).active ~ .indicator{
    transform: translateX(calc(80px * 4 + 5px));
}

@media only screen and (max-width: 1000px) {
    
    .website_brand .logo {
        height: 35px;
        width: 35px;
    }
    .mob_nav{
        display: flex;
    }
    .nav_bar {
        display: none;
    }
    .default_container_padding {
        padding-left: 0px;
    }
}

@media only screen and (max-width: 700px) {
    
    .mob_nav{
        height: 60px;
    }
    .mob_nav ul li{
        width: 60px;
        height: 60px;
    }
    .mob_nav ul li a .icon{
        line-height: 65px;
        font-size: 24px;
    }
    .mob_nav ul li.dp a .icon{
        height: 60px;
    }   
    .mob_nav ul li a .icon .dp_img{
        height: 40px;
        width: 40px;
    }
    .mob_nav ul li.active a .icon{
        transform: translateY(-30px);
    }
    .mob_nav ul li a .text{
        transform: translateY(30px);
    }
    .mob_nav ul li.active a .text{
        transform: translateY(10px);
    }
    .mob_nav ul .indicator{
        height: 60px;
        width: 60px;
    }   
    .mob_nav ul li:nth-child(1).active ~ .indicator{
        transform: translateX(calc(70px * 0 + 5px));
    }
    .mob_nav ul li:nth-child(2).active ~ .indicator{
        transform: translateX(calc(70px * 1 + 5px));
    }
    .mob_nav ul li:nth-child(3).active ~ .indicator{
        transform: translateX(calc(70px * 2 + 5px));
    }
    .mob_nav ul li:nth-child(4).active ~ .indicator{
        transform: translateX(calc(70px * 3 + 5px));
    }
    .mob_nav ul li:nth-child(5).active ~ .indicator{
        transform: translateX(calc(70px * 4 + 5px));
    }
}

@media only screen and (max-width: 500px) {
    
    
    .website_brand .logo {
        height: 30px;
        width: 30px;
    }
    .mob_nav{
        height: 50px;
    }
    .mob_nav ul li{
        width: 50px;
        height: 50px;
        margin-inline: 8px;
        transform: translateX(5px);
    }
    .mob_nav ul li a .icon{
        line-height: 60px;
        font-size: 20px;
    }
    .mob_nav ul li.dp a .icon{
        transform: translateY(-5px);
        height: 60px;
    }
    .mob_nav ul li.dp.active a .icon{
        transform: translateY(-30px);
    }
    .mob_nav ul li a .icon .dp_img{
        height: 35px;
        width: 35px;
    }
    .mob_nav ul li.active a .icon{
        transform: translateY(-30px);
    }
    .mob_nav ul li a .text{
        transform: translateY(30px);
    }

    .mob_nav ul li.active a .text{
        transform: translateY(6px);
        font-size: 13px;
    }
    .mob_nav ul .indicator{
        height: 50px;
        width: 50px;
    }   
    .mob_nav ul li:nth-child(1).active ~ .indicator{
        transform: translateX(calc(68px * 0 + 12px));
    }
    .mob_nav ul li:nth-child(2).active ~ .indicator{
        transform: translateX(calc(68px * 1 + 10px));
    }
    .mob_nav ul li:nth-child(3).active ~ .indicator{
        transform: translateX(calc(68px * 2 + 9px));
    }
    .mob_nav ul li:nth-child(4).active ~ .indicator{
        transform: translateX(calc(68px * 3 + 6px));
    }
    .mob_nav ul li:nth-child(5).active ~ .indicator{
        transform: translateX(calc(68px * 4 + 5px));
    }
}
@media only screen and (max-height: 400px) {   
    .mob_nav{
        display: none;
    }
}

.btn-close{
  border-radius: 4px;
  border: none;
  color: #333333;
  text-align: center;
  font-size: 14px;
  width: 150px;
  transition: all 0.5s;
  cursor: pointer;
  background-color: #265090;
  color: white;
}

.btn-close.fixed_btn{
    position: relative;
    bottom: 16vh;
    right: -44vw;
    z-index: 6;
  }

   .ant-modal{
    top: 5px !important;
  }
  .menu-modal .ant-modal-footer {
    padding: 28px 16px;
    position: absolute;
  }
  .menu-modal .ant-modal-wrap{
    overflow: scroll;
  }