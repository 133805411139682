.common-header-subcontent {
    background-image: linear-gradient(96deg,var(--blue) 0%,rgba(78,138,228,0.35) 100%);
    background-color: rgba(255,255,255,0);
    background-size: cover;
    height: 25vh;
    display: flex;
}

.common-header-subcontent div {
    display: flex;
    flex-direction: column;
    margin: auto 10%;
}

.common-header-container {
    position: relative;
}

.common-header-image {
    position: absolute;
    width: 100%;
    z-index: -1;
    height: 25vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.common-text-style {
    font-weight: 900;
    font-size: 30px;
    text-align: left;
    margin-bottom: 6px;
    color: rgb(255, 255, 255);
}

.common-subtext-style {
    font-weight: 300;
    font-size: 18px;
    text-align: left;
    color: rgb(255, 255, 255);
}

@media screen and (max-width:760px) {
    .common-header-subcontent, .common-header-image {
        height: 20vh;
    }

    .common-text-style { 
        font-size: 32px !important;
    }
    .common-subtext-style {
        font-size: 14px !important;
    }

    .common-header-subcontent div {
        display: flex;
        flex-direction: column;
        margin: auto 5%;
    }
}